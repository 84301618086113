import { FC } from 'react'
import tw from 'tailwind-styled-components'
import { useTranslation } from 'next-i18next'
import { NavLink } from './NavLink'

const Container = tw.div`container flex flex-col md:flex-row py-6 gap-8`
const Copyright = tw.p`text-sm text-gray-700`

export const Footer: FC = () => {
  const { t } = useTranslation()

  return (
    <div className='bg-white border-t border-gray-300'>
      <Container>
        <div className='flex gap-8'>
          <Copyright>{t('copyright')}</Copyright>
          <NavLink href='/rules'>{t('nav.rules')}</NavLink>
        </div>
        <div className='flex mt-3 md:mt-0 gap-8'>
          <NavLink href='/privacy-policy'>{t('nav.privacyPolicy')}</NavLink>
          <NavLink href='/about-us'>{t('nav.aboutUs')}</NavLink>
          <NavLink href='/contact-us'>{t('nav.contactUs')}</NavLink>
        </div>
      </Container>
    </div>
  )
}
