import { FC, ReactNode } from 'react'
import tw from 'tailwind-styled-components'

type Props = {
  title: ReactNode
  message?: ReactNode
}

const Container = tw.div`flex flex-1 flex-col items-center justify-center`
const Title = tw.p`font-semibold text-center text-3xl mb-3`

export const ErrorContainer: FC<Props> = ({ title, message }) => {
  return (
    <Container>
      <Title>{title}</Title>
      {message && <p className='text-gray-700'>{message}</p>}
    </Container>
  )
}
